<template>
  <div class="">
    <Breadcrumbs title="Attendance" />
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">


          <div class="table-responsive datatable-vue">
            <b-table
                ref="table"
                show-empty
                selected-variant="success"
                :items="tableRecords"
                :fields="tableFields"
                :filter="filter"
                :current-page="1"
                :per-page="perPage"
                @filtered="onFiltered"
                @row-selected="rowSelected"
            >
<!--              <template class="text-right" #cell(institute_name)="row">{{ row.item.institute.name }}</template>-->
<!--              <template class="text-right" #cell(student_name)="row">-->
<!--                {{ row.item.student.first_name }} {{ row.item.student.last_name }}-->
<!--              </template>-->
              <template class="text-right" #cell(details)="row">
                <p class="m-b-0 p-b-0"><strong>Class: </strong>{{row.item.class_schedule.class_detail.name}}</p>
                <b-row>
                  <b-col cols="12" md="6">
                    <p><strong>Start At: </strong>{{new Date(row.item.start_at).toDateString()}}</p>
                  </b-col>
                  <b-col cols="12" md="6">
                    <p><strong>End At: </strong>{{new Date(row.item.end_at).toDateString()}}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <p class="m-b-0 p-b-0"><strong>Seat: </strong>{{row.item.seat_number || 'N/A' }}</p>
                  </b-col>
                  <b-col cols="12" md="6">
                    <p class="m-b-0 p-b-0"><strong>Creator: </strong>{{row.item.student.first_name}} {{row.item.student.last_name}}</p>
                  </b-col>
                </b-row>
              </template>
              <template class="text-right" #cell(status)="row">
                <span v-if="row.item.status===0">Active</span>
                <span v-else>Inactive</span>
              </template>
              <template class="text-right" #cell(created_at)="row">{{new Date(row.item.created_at).toDateString()}}</template>
              <template #cell(action)="row" class="text-right">
                <!--                    <b-button class="btn-square" variant="primary">View</b-button>-->
                <b-button v-show="checkUserPermissions(user,['update_attendance'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                <b-button v-show="checkUserPermissions(user,['delete_attendance'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
              </template>
            </b-table>
          </div>


          <b-row >
            <b-col cols="4">
              Records: <strong>{{ totalRows }}</strong>
            </b-col>
            <b-col cols="8" class="my-1" v-if="totalRows">
              <b-pagination
                  align="right"
                  class="my-0"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  pills
              ></b-pagination>
            </b-col>
          </b-row>



          <!-- Begin Delete Modal -->
          <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
            <h6 class="m-b-10">Please confirm removal of following attendance record:</h6>
            <strong v-text="selected.id"></strong>
          </b-modal>
          <!-- End Delete Modal -->


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import CRUD_API from '@/services/crud'
import { mapState } from 'vuex'
export default {
  name: "attendance",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  mounted() { this.fetchData(); },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/attendances',
      tableFields: [
        { key: 'id', label: 'ID', sortable: true, },
        { key: 'institute.name', label: 'Institute', sortable: true, },
        // { key: 'student_name', label: 'Student', sortable: true, },
        // { key: 'creator.username', label: 'Marked By', sortable: true, },
        { key: 'details', label: 'Details', sortable: false, },
        // { key: 'seat_number', label: 'Seat No.', sortable: false, },
        // { key: 'status', label: 'Status', sortable: true, },
        { key: 'created_at', label: 'Create Date', sortable: true, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
    }
  },
  methods: {
    checkUserPermissions,
    rowSelected(item){
      this.selected = item
    },
    onFiltered() {},
    async fetchData() {
      await CRUD_API.index(this.api_base, {
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          status: 0
        }
      }).then((result)=>{
        console.log(result.data.data.data)
        this.tableRecords = result.data.data.data
      }).catch((error)=>{
        console.log("ERROR", error)
        this.$toasted.error("Records could not be retrieved!")
      })
    },

    clearFormModals() {
      this.selected = []
    },
    showEditModal(index, item, button) {},
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal() {
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Barrier removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    }
  },
}
</script>

<style scoped>

</style>
